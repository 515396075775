<template>
  <nav class="navbar navbar-default">
    <div class="container-fluid">
      <div class="navbar-header">
        <button
          :class="{'toggled': $sidebar.showSidebar}"
          @click.stop.prevent="toggleSidebar"
          type="button"
          class="navbar-toggle"
        >
          <span class="sr-only">Toggle navigation</span>
          <span class="icon-bar bar1" />
          <span class="icon-bar bar2" />
          <span class="icon-bar bar3" />
        </button>
        <a class="navbar-brand">{{ routeName }}</a>
      </div>
      <div class="collapse navbar-collapse">
        <ul class="nav navbar-nav navbar-right">
          <li class="open">
            <a href="#" class="dropdown-toggle btn-magnify" data-toggle="dropdown">
              <i class="ti-panel" />
              <p>Stats</p>
            </a>
          </li>
          <drop-down title="5 Notifications" icon="ti-bell">
            <li><a href="#">Notification 1</a></li>
            <li><a href="#">Notification 2</a></li>
            <li><a href="#">Notification 3</a></li>
            <li><a href="#">Notification 4</a></li>
            <li><a href="#">Another notification</a></li>
          </drop-down>
          <li>
            <a href="#" class="btn-rotate">
              <i class="ti-settings" />
              <p>
                Settings
              </p>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
export default {
  data () {
    return {
      activeNotifications: false
    }
  },
  computed: {
    routeName () {
      const { name } = this.$route
      return this.capitalizeFirstLetter(name)
    }
  },
  methods: {
    capitalizeFirstLetter (string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    toggleNotificationDropDown () {
      this.activeNotifications = !this.activeNotifications
    },
    closeDropDown () {
      this.activeNotifications = false
    },
    toggleSidebar () {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar)
      document.body.classList.toggle('nav-open')
    }
  }
}

</script>

<style lang="scss" scoped>
nav.navbar {
  z-index: unset;
}
</style>
