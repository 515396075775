<template>
  <div>
    <side-bar type="sidebar">
      <template slot="links">
        <sidebar-link to="/" name="Dashboard" icon="ti-panel" />
        <sidebar-link to="/userprofile" name="User Profile" icon="ti-user" />
        <sidebar-link to="/tablelist" name="Table List" icon="ti-view-list-alt" />
        <sidebar-link to="/typography" name="Typography" icon="ti-text" />
        <sidebar-link to="/icons" name="Icons" icon="ti-pencil-alt2" />
        <sidebar-link to="/maps" name="Map" icon="ti-map" />
        <sidebar-link to="/notifications" name="Notifications" icon="ti-bell" />
        <sidebar-link to="/login" name="Login" icon="ti-control-eject" />
        <sidebar-link to="/404" name="Error 404" icon="ti-na" />
      </template>
    </side-bar>

    <div class="wrapper">
      <client-only>
        <notifications />
      </client-only>

      <div ref="dashboardScrollerArea" @click="toggleSidebar" class="main-panel">
        <top-navbar />

        <dashboard-content />

        <content-footer />
      </div>
    </div>
  </div>
</template>
<script>
import 'perfect-scrollbar/css/perfect-scrollbar.css'
import PerfectScrollbar from 'perfect-scrollbar'
import TopNavbar from './TopNavbar.vue'
import ContentFooter from './ContentFooter.vue'
import DashboardContent from './Content.vue'

export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent
  },
  data () {
    return {
      perfectScrollbar: null
    }
  },
  mounted () {
    this.initScrollBarAsync()
    this.$watch('$route', this.perfectScrollbarUpdate, {
      immediate: true
    })
  },
  methods: {
    toggleSidebar () {
      if (this.$sidebar && this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false)
        document.body.classList.remove('nav-open')
      }
    },
    initScrollBarAsync () {
      this.perfectScrollbar = new PerfectScrollbar(this.$refs.dashboardScrollerArea)
    },
    perfectScrollbarUpdate () {
      this.perfectScrollbar.update()
    }
  }
}

</script>

<style lang="scss" scoped >
.main-panel {
  max-height: 100%;
  height: 100%;
}
</style>
