<template>
  <div class="form-group">
    <label v-if="label">
      {{ label }}
    </label>
    <input
      v-bind="$attrs"
      :value="value"
      @input="updateValue($event.target.value)"
      class="form-control border-input"
    >
  </div>
</template>
<script>
export default {
  inheritAttrs: false,
  props: {
    value: {
      type: [String, Number],
      default: null
    },
    label: {
      type: String,
      default: ''
    }
  },
  methods: {
    updateValue (value) {
      this.$emit('input', String(value))
    }
  }
}
</script>
<style>

</style>
