<template>
  <div
    v-click-outside="autocloseConfig"
    :class="{'off-canvas-sidebar': $sidebar.showSidebar}"
    :data-background-color="backgroundColor"
    :data-active-color="activeColor"
    class="sidebar"
  >
    <!--
            Tip 1: you can change the color of the sidebar's background using: data-background-color="white | brown | black"
            Tip 2: you can change the color of the active button using the data-active-color="primary | info | success | warning | danger"
        -->
    <!-- -->
    <div id="style-3" ref="sidebarScrollArea" class="sidebar-wrapper">
      <div v-if="showHeader" class="logo">
        <a href="#" class="simple-text">
          <div class="logo-img">
            <img src="/img/vue-logo.png" alt="">
          </div>
          {{ title }}
        </a>
      </div>
      <slot />
      <ul class="nav">
        <!--By default vue-router adds an active class to each route link. This way the links are colored when clicked-->
        <slot name="links">
          <sidebar-link
            v-for="(link,index) in sidebarLinks"
            :key="index"
            :to="link.path"
            :name="link.name"
            :icon="link.icon"
          />
        </slot>
      </ul>
      <moving-arrow :move-y="arrowMovePx" />
    </div>
  </div>
</template>
<script>
import 'perfect-scrollbar/css/perfect-scrollbar.css'
import PerfectScrollbar from 'perfect-scrollbar'
import MovingArrow from './MovingArrow.vue'
import SidebarLink from './SidebarLink'

export default {
  components: {
    MovingArrow,
    SidebarLink
  },
  props: {
    title: {
      type: String,
      default: 'Paper Dashboard'
    },
    backgroundColor: {
      type: String,
      default: 'brown',
      validator: (value) => {
        const acceptedValues = ['white', 'black', 'brown']
        return acceptedValues.includes(value)
      }
    },
    showHeader: {
      type: Boolean,
      default: true
    },
    activeColor: {
      type: String,
      default: 'info',
      validator: (value) => {
        const acceptedValues = [
          'primary',
          'info',
          'success',
          'warning',
          'danger'
        ]
        return acceptedValues.includes(value)
      }
    },
    sidebarLinks: {
      type: Array,
      default: () => []
    },
    autoClose: {
      type: Boolean,
      default: true
    }
  },
  provide () {
    return {
      autoClose: this.autoClose,
      addLink: this.addLink,
      removeLink: this.removeLink
    }
  },
  data () {
    return {
      linkHeight: 60,
      activeLinkIndex: 0,
      windowWidth: 0,
      isWindows: false,
      hasAutoHeight: false,
      links: []

    }
  },
  computed: {
    /**
     * Styles to animate the arrow near the current active sidebar link
     * @returns {{transform: string}}
     */
    arrowMovePx () {
      return this.linkHeight * this.activeLinkIndex
    },
    // workaround for: https://github.com/ndelvalle/v-click-outside/issues/143
    autocloseConfig () {
      return {
        handler: this.closeSidebar,
        isActive: this.$sidebar.showSidebar
      }
    }
  },
  mounted () {
    this.$watch('$route', this.findActiveLink, {
      immediate: true
    })
    this.initScrollBar()
  },
  methods: {
    findActiveLink () {
      this.links.forEach((link, index) => {
        if (link.isActive()) {
          this.activeLinkIndex = index
        }
      })
    },
    addLink (link) {
      const index = this.$slots.links.indexOf(link.$vnode)
      this.links.splice(index, 0, link)
    },
    removeLink (link) {
      const index = this.links.indexOf(link)
      if (index > -1) {
        this.links.splice(index, 1)
      }
    },
    closeSidebar () {
      if (this.autoClose && this.$sidebar.showSidebar) { this.$sidebar.displaySidebar(false) }
      document.body.classList.remove('nav-open')
    },
    initScrollBar () {
      this.scrollbar = new PerfectScrollbar(this.$refs.sidebarScrollArea)
    }
  }
}
</script>
<style>
</style>
