
<template>
  <div>
    <nav class="navbar navbar-transparent navbar-absolute">
      <div class="container">
        <div class="navbar-header">
          <router-link class="navbar-brand" to="/">
            sendform
          </router-link>
        </div>
      </div>
    </nav>
    <div class="wrapper wrapper-full-page">
      <div class="full-page login-page" data-color="light">
        <!--data-image="static/img/background/background-2.jpg" -->
        <!--   you can change the color of the filter page using: data-color="blue | azure | green | orange | red | purple" -->
        <div class="content">
          <div class="container">
            <div class="row">
              <div class="col-md-4 col-sm-6 col-md-offset-4 col-sm-offset-3">
                <div class="error">
                  <div class="card text-center">
                    <div class="header text-danger">
                      <h2 class="title">
                        Error {{ statusCode }}
                      </h2>
                    </div>
                    <div class="content text-center">
                      <svg xmlns="http://www.w3.org/2000/svg" width="90" height="90" fill="#DBE1EC" viewBox="0 0 48 48">
                        <path d="M22 30h4v4h-4zm0-16h4v12h-4zm1.99-10C12.94 4 4 12.95 4 24s8.94 20 19.99 20S44 35.05 44 24 35.04 4 23.99 4zM24 40c-8.84 0-16-7.16-16-16S15.16 8 24 8s16 7.16 16 16-7.16 16-16 16z" />
                      </svg>
                      <div class="text-center">
                        {{ message }}
                      </div>
                    </div>
                    <div class="footer text-center">
                      <a @click="goBack()" href="javascript:(void0)">
                        Go back
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <footer class="footer footer-transparent">
          <div class="container">
            <div class="copyright">
              &copy; Coded with
              <i class="fa fa-heart heart" /> by
              <a href="https://github.com/cristijora" target="_blank">Cristi Jora</a>.
              Designed by <a href="https://www.creative-tim.com/?ref=pdf-vuejs" target="_blank">Creative Tim</a>.
            </div>
          </div>
        </footer>
        <!--div class="full-page-background" style="background-image: url(static/img/background/background-2.jpg) "></div-->
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'NuxtError',
  layout: 'fullpage',
  props: {
    error: {
      type: Object,
      default: null
    }
  },
  computed: {
    statusCode () {
      return (this.error && this.error.statusCode) || 500
    },
    message () {
      return this.error.message
    }
  },
  methods: {
    goBack () {
      this.$router.back()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~/assets/sass/paper/_variables';

.navbar.navbar-absolute {
    position: absolute;
    width: 100%;
    z-index: 1030;
    color: $black-color !important;
}
.navbar-transparent .navbar-brand,
.footer .copyright,
.footer .copyright a {
  color: $black-color !important;
}

.full-page > .content {
  padding-top: 20vh;
}

.sidebar {
  display: none;

  &.off-canvas-sidebar {
    display: inherit;
  }
}

</style>
