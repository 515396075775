<template>
  <li v-click-outside="closeDropDown" :class="{open:isOpen}" @click="toggleDropDown" class="dropdown">
    <a class="dropdown-toggle btn-rotate" data-toggle="dropdown" href="javascript:void(0)">
      <slot name="title">
        <i :class="icon" />
        <p class="notification">{{ title }}
          <b class="caret" />
        </p>
      </slot>
    </a>
    <ul class="dropdown-menu">
      <slot />
    </ul>
  </li>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      isOpen: false
    }
  },
  methods: {
    toggleDropDown () {
      this.isOpen = !this.isOpen
    },
    closeDropDown () {
      this.isOpen = false
    }
  }
}
</script>

<style>
  .dropdown {
    cursor: pointer;
  }
</style>
